import React from 'react'
import "./ContactUs.css"

export const ContactUs = () => {

    return (
        <div className='contact_us_whole_page_div'>
            <div className='contact_us_first_main_div'>
                <div className='contact_us_heading_div'>
                    <h3>We’d love to hear from you! Send us a message using the form opposite, or <cite>email us</cite>.</h3>
                </div>
                <div>
                    <h3 className='contact_us_office_address_text'>Office Address </h3>
                </div>
                <div className='contact_us_delaronde_text'>618 Delaronde Crescent Saskatoon SK S7J3Z7 Canada <br /> Ostbanegade 27, 2100 Copenhagen, Denmark.</div>
                <div className='say_hello_div'> <span>Say Hello: </span>info@eleget.net</div>
            </div>
            <div className='contact_us_second_main_div'>
                <form action="">
                <div><input className='input_same' type="text" placeholder='Name*' required /></div>
                <div><input className='input_same' type="email" placeholder='Email*' required/></div>
                <div><input className='input_same' type="text" placeholder='your-subject*' required/></div>
                <div><textarea name="" id="" placeholder='Message*' required></textarea></div>
                <div><input className='submit_btn_contact_us' type="submit" value="Send Message" /></div>
                </form>
            </div>
        </div>
    )
}




