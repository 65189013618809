import React, { useState, useRef, useEffect } from 'react';
import Team from "../../Images/Team.png"
import Pen from "../../Images/Pen.png"
import Laptop from "../../Images/Laptop.png"
import Head from "../../Images/Head.png"
import "./AboutUs.css"

export const AboutUs = () => {
    const [isVisible1, setIsVisible1] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [isVisible3, setIsVisible3] = useState(false);
    const [isVisible4, setIsVisible4] = useState(false);

    const divRef1 = useRef(null);
    const divRef2 = useRef(null);
    const divRef3 = useRef(null);
    const divRef4 = useRef(null);

    const hasActivated1 = useRef(false);
    const hasActivated2 = useRef(false);
    const hasActivated3 = useRef(false);
    const hasActivated4 = useRef(false);


    const divStyle1 = {
        animation: isVisible1 ? 'slideInFromBottom 1s ease-out forwards' : 'none',
    };
    const divStyle2 = {
        animation: isVisible2 ? 'slideInFromBottom 1s ease-out forwards' : 'none',
    };
    const divStyle3 = {
        animation: isVisible3 ? 'slideInFromBottom 1s ease-out forwards' : 'none',
    };
    const divStyle4 = {
        animation: isVisible4 ? 'slideInFromLeft 1s ease-out forwards' : 'none',
    };


    const array1 = [
        {
            id: '1',
            iamge: Pen,
            title: 'UI/UX Design',
            description: 'Build the product you need on time with an experienced team that uses a clear and effective design process.',
            ref:divRef1,
            style:divStyle1,
            className:'about_us_same_div_one_11'
        },
        {
            id: '2',
            iamge: Laptop,
            title: 'Web and Mobile Development',
            description: 'Create an impactful web and mobile apps that fits your brand and industry within a shorter time frame.',
            ref:divRef2,
            style:divStyle2,
            className:'about_us_same_div_one_22'
        },
        {
            id: '3',
            iamge: Head,
            title: 'Dedicated development team',
            description: 'Hire a loyal software development team with niche skills and deep expertise.',
            ref:divRef3,
            style:divStyle3,
            className:'about_us_same_div_one_33'
        }
    ]

    useEffect(() => {
        const createObserver = (divRef, setIsVisible, hasActivated) => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting && !hasActivated.current) {
                        setIsVisible(true);
                        hasActivated.current = true;
                    }
                },
                { threshold: 0.1 }
            );

            const currentDiv = divRef.current;

            if (currentDiv) {
                observer.observe(currentDiv);
            }

            return () => {
                if (currentDiv) {
                    observer.unobserve(currentDiv);
                }
            };
        };

        const observersCleanup = [
            createObserver(divRef1, setIsVisible1, hasActivated1),
            createObserver(divRef2, setIsVisible2, hasActivated2),
            createObserver(divRef3, setIsVisible3, hasActivated3),
            createObserver(divRef4, setIsVisible4, hasActivated4),
        ];

        return () => observersCleanup.forEach((cleanup) => cleanup && cleanup());
    }, []);


    return (
        <div className='about_us_whole_page_div'>
            <div className='about_us_first_main_div' >
            {array1.map((val) => (
                <div key={val?.id} className={val?.className} ref={val?.ref} style={val?.style}>
                    <div>
                        <img src={val?.iamge} alt="" />
                    </div>
                    <h3 className='about_us_same_heading'>{val?.title}</h3>
                    <div className='about_us_same_div_p'>{val?.description}</div>
                </div>
                 ))}
            </div>
            <div className='about_us_second_main_div'>
                <div className='big_img_div'>
                    <img ref={divRef4} style={divStyle4} src={Team} alt="" />
                </div>
                <div className='leading_development_div'>
                    <h3 className='about_us_second_main_div_heading'> Leading Development House For <br /> Your Business Solution</h3>
                    <p>We are a digital consultancy, web and mobile development company that provides cutting edge engineering solutions, helping companies and enterprise clients untangle complex issues that always emerge during their digital evolution journey. We are a visionary and a reliable software engineering partner for start ups.</p>
                </div>
            </div>
        </div >
    )

}
