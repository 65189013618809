import React, { useState, useRef, useEffect } from 'react';
import "./Services.css"
import Startup from "../../Images/Startup.png"
import Web from "../../Images/Web.png"
import AppDesign1 from "../../Images/AppDesign1.png"
import ItConsulting from "../../Images/ItConsulting.png"
import Testing from "../../Images/Testing.png"
import UiUx from "../../Images/UiUx.png"

export const Services = () => {
    const [isVisible1, setIsVisible1] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [isVisible3, setIsVisible3] = useState(false);
    const [isVisible4, setIsVisible4] = useState(false);
    const [isVisible5, setIsVisible5] = useState(false);
    const [isVisible6, setIsVisible6] = useState(false);

    const divRef1 = useRef(null);
    const divRef2 = useRef(null);
    const divRef3 = useRef(null);
    const divRef4 = useRef(null);
    const divRef5 = useRef(null);
    const divRef6 = useRef(null);

    const hasActivated1 = useRef(false);
    const hasActivated2 = useRef(false);
    const hasActivated3 = useRef(false);
    const hasActivated4 = useRef(false);
    const hasActivated5 = useRef(false);
    const hasActivated6 = useRef(false);

    const divStyle1 = {
        animation: isVisible1 ? 'slideInFromRight 1s ease-out forwards' : 'none',
    };
    const divStyle2 = {
        animation: isVisible2 ? 'slideInFromRight 1s ease-out forwards' : 'none',
    };
    const divStyle3 = {
        animation: isVisible3 ? 'slideInFromRight 1s ease-out forwards' : 'none',
    };
    const divStyle4 = {
        animation: isVisible4 ? 'slideInFromRight 1s ease-out forwards' : 'none',
    };
    const divStyle5 = {
        animation: isVisible5 ? 'slideInFromRight 1s ease-out forwards' : 'none',
    };
    const divStyle6 = {
        animation: isVisible6 ? 'slideInFromRight 1s ease-out forwards' : 'none',
    };

    const array1 = [
        {
            id: 1,
            title: "Startup MVP",
            description: "We will break down your idea into pieces and put it back together into an MVP ready for market fit.",
            image: Startup,
            style: divStyle1,
            ref: divRef1
        },
        {
            id: 2,
            title: "Web Development",
            description: "We employ best practices and development methodologies for building effective applications.",
            image: Web,
            style: divStyle2,
            ref: divRef2
        },
        {
            id: 3,
            title: "App Design",
            description: "Create an impactful mobile app that fits your brand and industry within a shorter time frame.",
            image: AppDesign1,
            style: divStyle3,
            ref: divRef3
        }

    ]
    const array2 = [
        {
            id: 1,
            title: "IT Consulting",
            description: "Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.",
            image: ItConsulting,
            style: divStyle4,
            ref: divRef4
        },
        {
            id: 2,
            title: "QA & Testing",
            description: "Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.",
            image: Testing,
            style: divStyle5,
            ref: divRef5
        },
        {
            id: 3,
            title: "UI/UX",
            description: "Build the product you need on time with an experienced team that uses a clear and effective design process.",
            image: UiUx,
            style: divStyle6,
            ref: divRef6
        }

    ]
    useEffect(() => {
        const createObserver = (divRef, setIsVisible, hasActivated) => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting && !hasActivated.current) {
                        setIsVisible(true);
                        hasActivated.current = true;
                    }
                },
                { threshold: 0.1 }
            );

            const currentDiv = divRef.current;

            if (currentDiv) {
                observer.observe(currentDiv);
            }

            return () => {
                if (currentDiv) {
                    observer.unobserve(currentDiv);
                }
            };
        };

        const observersCleanup = [
            createObserver(divRef1, setIsVisible1, hasActivated1),
            createObserver(divRef2, setIsVisible2, hasActivated2),
            createObserver(divRef3, setIsVisible3, hasActivated3),
            createObserver(divRef4, setIsVisible4, hasActivated4),
            createObserver(divRef5, setIsVisible5, hasActivated5),
            createObserver(divRef6, setIsVisible6, hasActivated6),
        ];

        return () => observersCleanup.forEach((cleanup) => cleanup && cleanup());
    }, []);



    return (
        <div className='services_whole_page_div'>
            <div className='services_first_main_div'>
                <h3 className='our_services_text'>Our Services</h3>
                <div className='cras_mattis_text'>Cras mattis iudicium purus sit amet fermentum at nos hinc posthac, sitientis piros afros. Lorem ipsum dolor sit amet, consectetur adipisici elit, petierunt.</div>
            </div>
            <div className='services_second_and_third_main_div'>
                {array1.map((val) => (
                    <div key={val.id} className='hover_class' ref={val?.ref} style={val?.style}>
                        <div className='services_same_img_logo'>
                            <img src={val?.image} alt="" />
                        </div>
                        <div className='services_same_div_2'>
                            <h3>{val?.title}</h3>
                            <div className='paragraph'>{val?.description}</div>
                        </div>
                    </div>
                ))}

            </div>
            <div className='services_second_and_third_main_div'>

                {array2.map((val) => (
                    <div key={val.id} className='hover_class' ref={val?.ref} style={val?.style}>
                        <div className='services_same_img_logo'>
                            <img src={val?.image} alt="" />
                        </div>
                        <div className='services_same_div_2'>
                            <h3>{val?.title}</h3>
                            <div className='paragraph'>{val?.description}</div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}





