import './App.css';
import { AboutUs } from './Componenets/AboutUs/AboutUs';
import { ContactUs } from './Componenets/ContactUs/ContactUs';
import { Services } from './Componenets/Services/Services';
import { SoftwareDevelopment } from './Componenets/SoftwareDevelopment/SoftwareDevelopment';
import Loader from "./Images/loader.gif";
import { useEffect, useState } from 'react';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 1000);
    return () => clearTimeout(timer);
  }, []);


  return (
    <div className="App">
      {loading ? (
        <div className="loader-img">
          <img src={Loader} alt="" />
        </div>
      ) : (
        <>
          <SoftwareDevelopment />
          <AboutUs />
          <Services />
          <ContactUs />
        </>
      )}
    </div>
  );
}

export default App;



















